import {Header} from "./header";
import * as React from "react";
import {Footer} from "./landingpage/footer";
import "../components/landingpage/desc-formation.scss";
import {MetaTags} from "./metaTags";
import dataDockImg from "../img/datadock-logo.jpg";
import qualiopiLogoImg from "../img/logo-qualiopi.png";
import cgvPro from "../documents/CGV-formations-pro-WealCome.pdf";
import cgvParticuliers from "../documents/CGV-formations-wealcome-particuliers.pdf";
import reglementInterieur from "../documents/réglement-intérieur.pdf";
import {Link} from "gatsby";
import qualiopiCertification from "../documents/certification-qualiopi.pdf";

export const CommonLayoutFormation = ({
                                          metaTitle,
                                          metaDescription,
                                          title,
                                          PodcastPresentation,
                                          TextDescription,
                                          ImagesTechnosLogo,
                                          Schedule,
                                          trainingType,
                                          programmePDFs
                                      }) => {

    return <div>
        <MetaTags title={metaTitle} description={metaDescription}/>
        <Header fixedTop={true} backgroundTransition={false}/>
        <section className="ptb-100">
            <div className="main container">
                <Link to="/formations">
                    Voir les autres formations
                </Link>
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-7 col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 order-2 order-md-1">
                        <TextDescription/>
                    </div>
                    <div className="col-md-4 order-1 order-md-2 text-center formation-info">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <ImagesTechnosLogo/>
                                    <hr/>
                                </div>
                                <h4 style={{marginBottom: 0}}>Prochaine session</h4>
                                <p>par <a href="/#fondateur" target="_blank" rel="noopener noreferrer">Michaël
                                    AZERHAD</a></p>
                                <p style={{color: '#1ea19a'}}>{<Schedule/>}</p>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <a className="btn solid-btn btn-primary"
                                           style={{color: "#fff"}}
                                           href={"https://form.typeform.com/to/JsWAqF?trainingtype=" + trainingType}
                                           target="_blank" rel="noopener noreferrer">Je souhaite m'inscrire !</a>
                                    </div>
                                </div>
                                <hr/>
                                <PodcastPresentation/>
                                <h4>Tarif "particulier"</h4>
                                <h5>Pour les CDI seulement qui paient de leur poche</h5>
                                <p style={{fontSize: '1.6rem', marginBottom: 0}}><strong>400 euros TTC /
                                    personne</strong></p>
                                <p><strong><i>Pas de CPF possible</i></strong></p>
                                <div>
                                    <a href={cgvParticuliers} target="_blank" rel="noopener noreferrer"><i
                                        className="lni lni-download"/> Voir les
                                        conditions générales de ventes pour particuliers</a>
                                </div>
                                <hr/>
                                <h4>Tarif "professionel"</h4>
                                <h5>Pour les entreprises<br/> (y compris les Freelances)</h5>
                                <p style={{fontSize: '1.6rem', marginBottom: 0}}><strong>1500 euros HT /
                                    personne</strong></p>
                                <p><strong><span style={{color: 'green', fontSize: '18px'}}>Finançable par les organismes financeurs  <i
                                    className="lni lni-checkmark-circle"/></span></strong></p>
                                <p><strong style={{color: 'green'}}>Wealcome est <a
                                        href={qualiopiCertification} target="_blank" rel="noopener noreferrer">certifiée
                                        Qualiopi</a>, ce qui vous permet
                                    de faire une
                                    demande de prise en charge par vos <span
                                        style={{color: 'black'}}>OPCO (Opco-Atlas, etc.) et les autres financeurs (FIFPL, FNE, etc.)</span></strong></p>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <img src={qualiopiLogoImg} alt="Qualiopi" width="100%"/>
                                    </div>
                                </div>
                                <div>
                                    <a href={cgvPro} target="_blank" rel="noopener noreferrer"><i
                                        className="lni lni-download"/> Voir les conditions
                                        générales de ventes pour professionnels</a>
                                </div>
                                <hr/>
                                <h4>Programme en version PDF</h4>
                                <ul>
                                    {programmePDFs.map((pdf, index) => {
                                        return <li key={index}><a href={pdf} target="_blank"
                                                                  rel="noopener noreferrer"><i
                                            className="lni lni-download"/> Télécharger le
                                            programme {programmePDFs.length > 1 ? index + 1 : ''} en
                                            version PDF</a></li>
                                    })}
                                </ul>
                                <hr/>
                                <h4>Règlement intérieur</h4>
                                <div>
                                    <a href={reglementInterieur} target="_blank" rel="noopener noreferrer"><i
                                        className="lni lni-download"/> Voir le règlement intérieur</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>;

}
